<template>
  <div class="container">
    <h1 class="h3 mb-3">Messages</h1>

    <div class="card">
      <div class="row no-gutters message-wrapper">
        <div class="col-12 col-lg-5 col-xl-3 border-right">
          <div class="px-4 d-none d-md-block">
            <div class="media align-items-center">
              <div class="media-body">
                <input
                  type="text"
                  class="form-control my-3"
                  placeholder="Search..."
                  v-model="searchText"
                />
              </div>
            </div>
          </div>

          <a
            href="#"
            class="list-group-item list-group-item-action border-0"
            v-for="(message, index) in selectedMessages"
            :key="index"
            @click="read(message)"
          >
            <div class="float-right text-muted">
              <small :class="message.opened ? ' opened-row' : ''">{{
                formatDate(message.sent)
              }}</small>
            </div>
            <div class="media">
              <div class="d-inline-block">
                <avatar
                  :username="message.sender.name"
                  :src="message.sender.avatar"
                  :size="40"
                  class="avatar my-0"
                ></avatar>
              </div>
              <div class="media-body ml-3">
                <span :class="message.opened ? ' opened-row' : ''">
                  {{ message.sender.name }}
                </span>
                <div :class="'small' + (message.opened ? ' opened-row' : '')">
                  {{ subjectSnippet(message.subject) }}
                </div>
              </div>
            </div>
          </a>

          <hr class="d-block d-lg-none mt-1 mb-0" />
        </div>
        <div class="col-12 col-lg-7 col-xl-9">
          <div class="py-2 px-4 border-bottom d-none d-lg-block" v-if="message">
            <div class="media align-items-center py-1">
              <div class="position-relative">
                <avatar
                  :username="message.sender.name"
                  :src="message.sender.avatar"
                  :size="40"
                  class="avatar my-0"
                ></avatar>
              </div>
              <div class="media-body pl-3">
                <strong>{{ message.sender.name }}</strong>
                <div class="text-muted small">{{ message.subject }}</div>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="mr-1 px-3"
                  v-if="!message.opened"
                  @click="markRead()"
                >
                  <i class="far fa-envelope-open"></i> Mark Read
                </b-button>
                <b-button
                  variant="primary"
                  class="mr-1 px-3"
                  v-if="message.opened"
                  @click="markUnread()"
                >
                  <i class="far fa-envelope"></i> Mark Unread
                </b-button>
                <b-button
                  variant="danger"
                  class="mr-1 px-3"
                  @click="deleteMessage()"
                >
                  <i class="far fa-trash-alt"></i> Delete
                </b-button>
              </div>
            </div>
          </div>

          <div class="position-relative">
            <div class="chat-messages p-4" v-if="message">
              <div class="chat-message-left pb-4">
                <div>
                  <avatar
                    :username="message.sender.name"
                    :src="message.sender.avatar"
                    :size="40"
                    class="avatar my-0 mr-1"
                  ></avatar>
                  <div class="text-muted small text-nowrap mt-2">
                    {{ formatTime(message.sent) }}
                  </div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                  <div class="font-weight-bold mb-1">
                    {{ message.sender.name }}
                  </div>
                  <p v-html="message.text"></p>
                </div>
              </div>

              <div class="chat-message-right pb-4" v-if="message.actions">
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                  <b-button
                    v-for="(action, index) in message.actions"
                    :key="index"
                    class="mx-1"
                    variant="outline-primary"
                    @click="performAction(action.action, action.value)"
                    :disabled="action.disabled === true"
                    >{{ action.text }}</b-button
                  >
                </div>
              </div>

              <div class="chat-message-left pb-4" v-if="message.response">
                <div>
                  <avatar
                    :username="message.response.sender.name"
                    :src="message.response.sender.avatar"
                    :size="40"
                    class="avatar my-0 mr-1"
                  ></avatar>
                  <div class="text-muted small text-nowrap mt-2">
                    {{ formatTime(message.response.sent) }}
                  </div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                  <div class="font-weight-bold mb-1">
                    {{ message.response.sender.name }}
                  </div>
                  {{ message.response.text }}
                </div>
              </div>

              <!-- <div class="chat-message-right pb-4">
                <div>
                  <img
                    src="@/assets/img/avatars/avatar.jpg"
                    class="rounded-circle mr-1"
                    alt="Avatar"
                    width="40"
                    height="40"
                  />
                  <div class="text-muted small text-nowrap mt-2">2:35 am</div>
                </div>
                <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                  <div class="font-weight-bold mb-1">You</div>
                  Cum ea graeci tractatos.
                </div>
              </div> -->
            </div>

            <div class="p-4" v-else>
              <b-alert variant="info" show>
                <div class="alert-message" v-if="searchText.length === 0">
                  <i class="fas fa-info-circle"></i> You have
                  {{ unreadCount }} unread messages and {{ totalCount }} total
                  messages.
                </div>
                <div class="alert-message" v-if="searchText.length > 0">
                  <i class="fas fa-info-circle"></i> Results: {{ resultCount }}
                </div>
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Messages from "@/firebase/messages";
import { DateTime } from "luxon";
import Avatar from "vue-avatar";

export default {
  name: "Messages",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    Avatar,
  },
  data() {
    return {
      ref: "messages",
      messages: [],
      messageKey: null,
      message: null,
      searchText: "",
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject(this.ref + "/" + uid, null, "messages");
        }
      },
    },
    routeId: {
      immediate: true,
      handler(id) {
        if (id) {
          const index = this.getIndex(this.messages, id);
          const message = this.messages[index];
          console.log("Message", index, message);
          this.read(message);
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
    unreadCount() {
      return this.messages.filter((message) => !message.opened).length;
    },
    totalCount() {
      return this.messages.length;
    },
    unreadMessages() {
      return this.messages.filter((message) => {
        return message.opened === false;
      });
    },
    searchResults() {
      return this.messages.filter((message) => {
        return (
          message.sender.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          message.subject
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          message.text.toLowerCase().includes(this.searchText.toLowerCase())
        );
      });
    },
    resultCount() {
      return this.searchResults.length;
    },
    selectedMessages() {
      if (this.searchText.length > 0) {
        return this.searchResults;
      } else {
        return this.messages;
      }
    },
    routeId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    formatDate(date) {
      const dt = DateTime.fromMillis(date);
      return dt.toLocaleString();
    },
    formatTime(date) {
      const dt = DateTime.fromMillis(date);
      return String(dt.toLocaleString(DateTime.TIME_SIMPLE)).toLowerCase();
    },
    searchMessages() {
      this.searchText = this.search;
    },
    deleteMessage() {
      this.deleteObject(this.ref + "/" + this.userUid, this.messageKey)
        .then(() => {
          this.message = null;
          this.notyf.success("Message deleted successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while deleting the message. Please try again later."
          );
        });
    },
    subjectSnippet(subject) {
      return subject && subject.length > 22
        ? subject.substr(0, 22) + "..."
        : subject;
    },
    read(message) {
      this.messageKey = message[".key"];
      this.message = { ...message };
    },
    markRead(quiet = false) {
      this.message.opened = true;
      const message = { ...this.message };

      this.updateObject(this.ref + "/" + this.userUid, this.messageKey, message)
        .then(() => {
          if (!quiet) {
            this.notyf.success("Message updated successfully.");
          }
        })
        .catch((error) => {
          console.log(error);
          if (!quiet) {
            this.notyf.error(
              "An error occurred while updating the message. Please try again later."
            );
          }
        });
    },
    markUnread() {
      this.message.opened = false;
      const message = { ...this.message };

      this.updateObject(this.ref + "/" + this.userUid, this.messageKey, message)
        .then(() => {
          this.notyf.success("Message updated successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while updating the message. Please try again later."
          );
        });
    },
    refreshEmail() {
      const index = this.getIndex(this.messages, this.messageKey);
      const message = this.messages[index];
      this.message = { ...message };
      console.log("Refresh", index, message);
    },
    performAction(name, value) {
      this.markRead(true);
      const m = new Messages();
      m.performAction(this.messageKey, name, value)
        .then(() => {
          setTimeout(() => {
            this.refreshEmail();
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while performing the action. Please try again later."
          );
          this.sending = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.message-wrapper {
  min-height: 400px;
}

.opened-row {
  color: #6c757d !important;
  font-style: italic;
}

.chat-messages {
  overflow-y: auto;
}
</style>
